import { createSlice } from '@reduxjs/toolkit';
import { devicesAddAllDevices, devicesAddDevice, devicesDeleteDevice, devicesModifyDevice } from './devicesSlice';

const initialState = {
    data:[],
    dataLive:[],
    dataHistory:[],
    dataLast:[],
    intervalType:"custom",
    customIntervalType:"live",
    count: 0
};

export const dataSlice = createSlice({
    name: 'data',
    initialState,

    reducers: {
        dataUpdateData: {
            reducer: (state, action) => {

                const oldData = state.data
                const oldDataLive = state.dataLive
                const oldDataHistory = state.dataHistory
                const oldDataLast = state.dataLast

                const index = oldData.findIndex(element => element.id === action.payload.deviceMeta.device_id)
                const indexlive = oldDataLive.findIndex(element => element.id === action.payload.deviceMeta.device_id)
                const indexHistory = oldDataHistory.findIndex(element => element.id === action.payload.deviceMeta.device_id)
                const indexLast = oldDataLast.findIndex(element => element.id === action.payload.deviceMeta.device_id)
                
                if((index >= 0) && ((state.intervalType!=="custom") && (state.intervalType!=="none")))
                {
                    let newArray = [...state.data];
                    newArray[index].data.push(action.payload.deviceData);
                    state.data = newArray;
                }

                if(indexlive >= 0 && state.intervalType!=="none")
                {
                    let newArray = [...state.dataLive];
                    newArray[indexlive].data.push(action.payload.deviceData);
                    state.dataLive = newArray;
                }

                if(indexHistory >= 0 && state.intervalType!=="none")
                {
                    let newArray = [...state.dataHistory];
                    newArray[indexHistory].data.push(action.payload.deviceData);
                    state.dataHistory = newArray;
                }

                if(indexLast >= 0)
                {
                    let newArray = [...state.dataLast];
                    newArray[indexLast].data = action.payload.deviceData;
                    state.dataLast = newArray;
                }
                
            },
            prepare: (message) => {

                return { payload: message }
            },
        },
        dataUpdateAllData: {
            reducer: (state, action) => {

                const oldData = state.data;

                const index = oldData.findIndex(element => element.id === action?.payload?.sensorId);

                state.intervalType = action?.payload?.intervalType;
                
                if(index >= 0)
                {
                    let newArray = [...state.data];
                    newArray[index].data = action.payload.data;
                    state.data = newArray;
                }
            },
            prepare: (message) => {
                
                return { payload: message }
            },
        },
        dataUpdateAllHistroyData: {
            reducer: (state, action) => {

                const oldDataHistory = state.dataHistory;

                const indexhistory = oldDataHistory.findIndex(element => element.id === action?.payload?.sensorId);
                
                if(indexhistory >= 0)
                {
                    let newArray = [...state.dataHistory];
                    newArray[indexhistory].data = action?.payload?.data;
                    state.dataHistory = newArray;
                }
            },
            prepare: (message) => {
                
                return { payload: message }
            },
        },
        dataResetData: {
            reducer: (state, action) => {
                const oldData = state.data
                const index = oldData.findIndex(element => element.id === action.payload.sensorId)
                let newArray = [...state.data];
                newArray[index].data = [
                    {
                        data: {},
                        body: {
                            avg:"",
                            reported_at: "Waiting",
                        }
                    }
                ]
                state.data = newArray
            }
        },
        dataResetDataAll: {
            reducer: (state) => {
                const newData = state.data.map(element => ({
                    ...element,
                    data: [
                        {
                            data: {},
                            body: {
                                avg:"",
                                reported_at: "Waiting",
                            }
                        }
                    ]
                }));
                // Aggiorna lo stato con il nuovo array 'data'
                state.data = newData;
            }
        },        
        dataResetDataLive: {
            reducer: (state, action) => {
                const oldData = state.dataLive
                const index = oldData.findIndex(element => element.id === action.payload.sensorId)
                let newArray = [...state.dataLive];
                newArray[index].data = [
                    {
                        data: {},
                        body: {
                            avg:"",
                            reported_at: "Waiting",
                        }
                    }
                ]
                state.dataLive = newArray
            }
        },
        dataResetDataLiveAll: {
            reducer: (state) => {
                const newData = state.dataLive.map(element => ({
                    ...element,
                    data: [
                        {
                            data: {},
                            body: {
                                avg:"",
                                reported_at: "Waiting",
                            }
                        }
                    ]
                }));
                state.dataLive = newData;
            }
        },
        dataResetDataHistory: {
            reducer: (state, action) => {
                const oldData = state.dataHistory
                const index = oldData.findIndex(element => element.id === action.payload.sensorId)
                let newArray = [...state.dataHistory];
                newArray[index].data = [
                    {
                        data: {},
                        body: {
                            avg:"",
                            reported_at: "Waiting",
                        }
                    }
                ]
                state.dataHistory = newArray
            }
        },
        dataResetDataHistoryAll: {
            reducer: (state) => {
                const newData = state.dataHistory.map(element => ({
                    ...element,
                    data: [
                        {
                            data: {},
                            body: {
                                avg:"",
                                reported_at: "Waiting",
                            }
                        }
                    ]
                }));
                state.dataHistory = newData;
            }
        },
        dataUpdateIntervalType: {
            reducer: (state, action) => {
                state.intervalType = action?.payload
            }
        },
        dataUpdateCustomIntervalType: {
            reducer: (state, action) => {
                state.customIntervalType = action?.payload
            }
        }
    },
    extraReducers: (builder) => { 
        builder
            .addCase(devicesAddDevice, (state, action) => {

                const oldData = state.data;
                const oldDataLast = state.dataLast;

                const newDevice = {
                    id: action.payload?.id,
                    dev_eui: action.payload?.dev_eui,                   
                    data: 
                    [
                        {
                            data: {},
                            body: {
                                avg:"",
                                reported_at: "Waiting",
                            }
                        }
                    ]
                }

                const newDeviceLast = {
                    id: action.payload?.id,
                    dev_eui: action.payload?.dev_eui,                   
                    data:
                    {
                        data: {},
                        body: {
                            avg:"",
                            reported_at: "Waiting",
                        }
                    }
                }

                let newArray = [...oldData];
                let newArraylast = [...oldDataLast];

                newArray.push(newDevice);
                newArraylast.push(newDeviceLast);

                state.data = newArray;
                state.dataLive = newArray;
                state.dataHistory = newArray;

                state.dataLast = newArraylast;
            })
            .addCase(devicesAddAllDevices, (state, action) => {

                let oldData = state.data;
                let oldDataLast = state.dataLast;

                action.payload.forEach(element => {

                    const newDevice = {
                        id: element?.id,
                        dev_eui: element?.dev_eui,                   
                        data: 
                        [
                            {
                                data: {},
                                body: {
                                    avg:"",
                                    reported_at: "Waiting",
                                }
                            }
                        ]
                    }

                    const newDeviceLast = {
                        id: element?.id,
                        dev_eui: element?.dev_eui,                   
                        data: 
                        {
                            data: {},
                            body: {
                                avg:"",
                                reported_at: "Waiting",
                            }
                        }
                    }

                    let newArray = [...oldData];;
                    let newArrayLast = [...oldDataLast];
                    newArray.push(newDevice);
                    newArrayLast.push(newDeviceLast);

                    oldData = newArray;
                    oldDataLast = newArrayLast;

                    state.data = newArray;
                    state.dataLive = newArray;
                    state.dataHistory = newArray;

                    state.dataLast = newArrayLast;
                })
            })
            // You can chain calls, or have separate `builder.addCase()` lines each time
            .addCase(devicesDeleteDevice, (state, action) => {

                const oldData = state?.data

                const index = oldData.findIndex(element => element.id === action.payload)
                
                let newArray = [...oldData];
                newArray.splice(index, 1)
                state.data = newArray
            })
            .addCase(devicesModifyDevice, (state, action) => {

                /*const oldData = state.data

                const index = oldData.map(function(element) {
                    return element.id; 
                }).indexOf(action.payload.id)
                
                let newArray = [...oldData];
                newArray[index] = action.payload;
                state.data = newArray*/
            })
            // and provide a default case if no other handlers matched
            .addDefaultCase((state, action) => {
                
            })
    },
});
 
export const {  
                dataUpdateData,
                dataUpdateAllData,
                dataUpdateAllHistroyData,
                dataResetData,
                dataResetDataAll,
                dataResetDataLive,
                dataResetDataLiveAll,
                dataResetDataHistory,
                dataResetDataHistoryAll,
                dataUpdateIntervalType,
                dataUpdateCustomIntervalType } = dataSlice.actions;

export const selectData = (state) => state.data.data
export const selectDataLive = (state) => state.data.dataLive
export const selectDataHistory = (state) => state.data.dataHistory
export const selectDataLast = (state) => state.data.dataLast
export const selectIntervalType = (state) => state.data.intervalType
export const selectCustomIntervalType = (state) => state.data.customIntervalType

export default dataSlice.reducer;
