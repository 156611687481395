import React, { useState } from 'react';
import DashboardDynamicChart from '../../../DashboardCharts/DashboardDynamicChart';
import { Box, Dialog, FormControl, IconButton, MenuItem, Stack, Tooltip, Select, Tab, Tabs, TextField, Typography, Button  } from '@mui/material';
import { dateFormat } from '../../../../../utils/utils';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import SouthWestIcon from '@mui/icons-material/SouthWest';
import { useCustomNavigate } from '../../../../../navigation/customNavigate';

function TabPanel(props) {

  const { children, value, index, ...other } = props;

  return (
      <div
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
      >
          {value === index && (
              <Box sx={{ p: 3 }}>
                  <Typography>
                      {children}
                  </Typography>
              </Box>
          )}
      </div>
  );
}

const DashboardWidgetSimpleChartConsumption = ({widgetIndex, widgetData, deviceId, data, avgEnabled, liveAvgEnabled, lastElBlEnabled, loading, intervalType, fullScreen, enlargeEnable, modifyEnable, handleModify}) => {

  const gData = [];
  const gLabels = [];

  const avgData = [];
  const avgLabels = [];

  let timeUnit = '';

  const avgDataByInterval = {};

  const reversedArray = data?.data;

  const [openGraphModDialog, setOpenGraphModDialog] = useState(false);

  const customNavigate = useCustomNavigate()

  const [tabValue, setTabValue] = useState(0);

  const [widgetD, setWidgetD] = useState(widgetData)
  const [widgetUpdating, setWidgetUpdating] = useState(widgetData)

  const handleColorChange = (event, index) => {

    let updatedWidgetD = JSON.parse(JSON.stringify(widgetUpdating));

    if (updatedWidgetD.specMeta && updatedWidgetD.specMeta.labels) {
      let updatedLabels = [...updatedWidgetD.specMeta.labels];
      updatedLabels[index].color = event.target.value;
      updatedWidgetD.specMeta.labels = updatedLabels;
    }
    setWidgetUpdating(updatedWidgetD);
  };

  const handleLabelChange = (event, index) => {

    let updatedWidgetD = JSON.parse(JSON.stringify(widgetUpdating));

    if (updatedWidgetD.specMeta && updatedWidgetD.specMeta.labels) {
      let updatedLabels = [...updatedWidgetD.specMeta.labels];
      updatedLabels[index].label = event.target.value;
      updatedWidgetD.specMeta.labels = updatedLabels;
    }
    setWidgetUpdating(updatedWidgetD);
  };
  
  const colorOptions = [
    { value: '#FF4F5A', label: `#FF4F5A` }, // Red
    { value: '#48BB78', label: `#48BB78` }, // Green
    { value: '#ECC94C', label: `#ECC94C` }, // Yellow
    { value: '#FCB419', label: `#FCB419` }, // Orange
    { value: '#031AE8', label: `#031AE8` }, // Blue 
    { value: '#FC19B8', label: `#FC19B8` }, // Purple
    { value: '#000000', label: `#000000` }, // Black 
  ];

  const handleGraphModTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  let intervalGain=0
  let intervalMinutes=0;
  if (intervalType==='day') {
    timeUnit = 'minute'
    intervalMinutes = 5;
    intervalGain = 1/12;
  }
  else if (intervalType==='week')
  {
    timeUnit = 'hour'
    intervalMinutes = 60;
    intervalGain = 1;
  }
  else if(intervalType==='month' || intervalType==='all')
  {
    timeUnit = 'day'
    intervalMinutes = 1440;
    intervalGain = 24;
  }
  else{
    intervalGain=1/60
    intervalMinutes = 0;
  }

  reversedArray?.forEach((e) => {
    const avg = e?.avg;
    const reported_at = e.reported_at;
    const data = e?.data;
    const dataInfo = e?.dataInfo;
    
    if (reported_at && reported_at !== "Waiting") {
      if (!avgEnabled || (avg !== null && avg !== undefined)) {
          for(let i = 0; i < data.length; i++ )
          {
            gData.push(data[i].convertedVal);
            let date = new Date(data[i].t);
            let formattedDate = dateFormat(date);
            gLabels.push(formattedDate);
          }
      } else if (liveAvgEnabled && (avg === null || avg === undefined)) {
          avgData.push(dataInfo);
          let date = new Date(reported_at);
          let formattedDate = dateFormat(date);
          avgLabels.push(formattedDate);
      }
    }
  });

  if(liveAvgEnabled) {

    avgData.forEach((value, index) => {
      const timestamp = avgLabels[index];
      const date = new Date(timestamp);

      const intervalStart = new Date(date);

      let minute = 0;
      if(intervalMinutes < 5)
      {
        // Calcola il minuto dell'ora
        minute = date.getMinutes();
      }
      else if(intervalMinutes >= 5 && intervalMinutes < 60)
      {
        // Calcola il minuto dell'ora
        minute = date.getMinutes();
      }
      else if(intervalMinutes === 60)
      {
        // Calcola l'ora
        minute = (date.getHours() * 60);
        intervalStart.setHours(0);
      }
      else
      {
        // Calcola il giorno
        minute = (date.getDate() * 1440);
        intervalStart.setHours(0);
        intervalStart.setDate(0);
      }

      // Calcola l'indice dell'intervallo
      const intervalIndex = Math.floor(minute / intervalMinutes);

      // Calcola l'inizio dell'intervallo
      intervalStart.setMinutes((intervalIndex) * intervalMinutes);
      intervalStart.setSeconds(0);
      intervalStart.setMilliseconds(0);
    
      const intervalKey = intervalStart.toISOString();
    
      if (!avgDataByInterval[intervalKey]) {
        avgDataByInterval[intervalKey] = [];
      }
    
      avgDataByInterval[intervalKey].push(value);
    });
  }
  
  const intervalAverages = {};

  for (const intervalKey in avgDataByInterval) {
    const intervalData = avgDataByInterval[intervalKey];
    const intervalAverage = {};
  
    for (const data of intervalData) {
      for (const key in data) {
        if (intervalAverage[key] === undefined) {
          intervalAverage[key] = (data[key]/data["elementNum"]);
        } else {
          intervalAverage[key] += (data[key]/data["elementNum"]);
        }
      }
    }
  
    const numDataPoints = intervalData.length;

    for (const key in intervalAverage) {
      intervalAverage[key] /= numDataPoints;
      //intervalAverage[key] /= (60*intervalGain);
    }
  
    intervalAverages[intervalKey] = intervalAverage;
  }

  for(const intervalKey in intervalAverages)
  {
    gData.push(intervalAverages[intervalKey])
    let date = dateFormat(new Date(intervalKey))
    gLabels.push(date)
  }

  function handleOpenInNewButtonClick() {

    const obj = {
      "chartType": "DashboardWidgetSimpleChartConsumption",
      "deviceId": deviceId,
      "widgetIndex": widgetIndex,
      "widgetType": widgetD?.type,
      "intervalType": intervalType,
      "gain":1
    }

    customNavigate( `/DashboardPage/DashboardDeviceScope/`, `/DashboardPage/ChartPage/`, obj, {} );
  };

  function handleModifyButtonClick() {

  }

  function handleOpenGraphModButtonClick() {
    setOpenGraphModDialog(true)
  }

  function handleCloseGraphModButtonClick() {
    setOpenGraphModDialog(false)
  }

  function _handleModify() {
    setWidgetD(widgetUpdating)
    handleModify(widgetIndex, widgetUpdating)
    setOpenGraphModDialog(false)
  }

  function _handleCancel() {
    setWidgetUpdating(widgetD)
    setOpenGraphModDialog(false)
  }

  function handleCloseInNewButtonClick() {

    const obj = {
      "deviceId": deviceId,
      "intervalType": intervalType
    }

    customNavigate( `/DashboardPage/ChartPage/`, `/DashboardPage/DashboardDeviceScope/`, obj, {} );
  };

  return (
    <Box width="100%" height="100%" position="relative" p={1}>
      <Stack width="100%" height="100%" display="flex" alignItems="center" justifyContent="center">
        { !fullScreen ?
          (
            <Stack direction="row" alignItems="center" spacing={1} p={0.5} alignSelf="end">
              <Tooltip title="Expand">
                <span>
                  <IconButton
                    onClick={handleOpenInNewButtonClick}
                    disabled={loading || !enlargeEnable}
                    size="small"
                  >
                    <OpenInNewOutlinedIcon fontSize="inherit" />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title="Modify">
                <span>
                  <IconButton
                    onClick={handleOpenGraphModButtonClick}
                    disabled={loading || !modifyEnable}
                    size="small"
                  >
                    <SettingsOutlinedIcon fontSize="inherit" />
                  </IconButton>
                </span>
              </Tooltip>
            </Stack>
          ) : (
            <Stack direction="row" alignItems="center" spacing={1} alignSelf="end">
              <Tooltip title="Reduce">
                <span>
                  <IconButton
                    onClick={handleCloseInNewButtonClick}
                    disabled={loading}
                  >
                    <SouthWestIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Stack>
          )
        }
        <Box
          width="95%"
          height="95%"
          position="relative"
        >
          <DashboardDynamicChart
            data={gData}
            type={intervalType}
            sign={widgetD?.specMeta?.sign ?? ""}
            range={widgetD?.specMeta?.range ?? [-300,300]}
            channel={widgetD?.specMeta?.channel}
            loading={loading}
            labels={gLabels}
            labTitle={widgetD?.specMeta?.labels[0].label}
            chartType={'line'}
            color={widgetD?.specMeta?.labels[0].color}
            fill={true}
            avgEnabled={avgEnabled}
            gain={intervalGain}
            averageLabels={avgLabels}
            fullScreen={fullScreen}
            timeUnit={timeUnit}
            intervalMinutes={intervalMinutes}
            lastElBlEnabled={lastElBlEnabled}
          />
        </Box>
      </Stack>
      <Dialog
        onClose={handleCloseGraphModButtonClick}
        open={openGraphModDialog}
      >
        <Box sx={{ width:'100%', bgcolor: 'background.paper' }}>
          <Tabs value={tabValue} onChange={handleGraphModTabChange} centered>
            <Tab label="CHANNELS" />
          </Tabs>
        </Box>
        <TabPanel
            value={tabValue}
            index={0}
            height="100%"
            width="100%"
        >
          <Stack p={1} spacing={2}>
            {widgetD?.specMeta?.labels.map((selected, index) => (
              <Stack
                key={index}
                spacing={2}
                display="flex"
                direction="row"
                justifyItems="space-around"
              >
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Typography>
                    {index}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Typography
                    sx={{
                      align:'center'
                    }}
                  >
                    <TextField
                        value={widgetUpdating?.specMeta?.labels[index].label}
                        inputProps={{ maxLength: 50 }}
                        onChange = {(event) => handleLabelChange(event, index)}
                    />
                  </Typography>
                </Box>
                <Box
                  sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                  <FormControl 
                    key={index}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <Select
                        value={widgetUpdating?.specMeta?.labels[index].color}
                        onChange={(event) => handleColorChange(event, index)}
                        inputProps={{ IconComponent: () => null, disableUnderline: true }}
                        sx={{
                          width: '100%',
                          height: '50%',
                          backgroundColor: widgetUpdating?.specMeta?.labels[index].color,
                        }}
                        renderValue={() => (
                        <Box
                            sx={{
                              width: '40%',
                              backgroundColor: widgetUpdating?.specMeta?.labels[index].color,
                            }}
                          >
                        </Box>
                      )}
                    >
                      {colorOptions.map((colorOption) => (
                        <MenuItem 
                          key={colorOption.value} 
                          value={colorOption.value}>
                            <Box
                                sx={{
                                  width: '100%',
                                  paddingBottom: '100%',
                                  backgroundColor: colorOption.value,
                              }}
                            >
                          </Box>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Stack>
            ))}
          </Stack>
        </TabPanel>
        <TabPanel
            value={tabValue}
            index={1}
            height="100%"
            width="100%"
        >
          HERE 1
        </TabPanel>
        <TabPanel
            value={tabValue}
            index={2}
            height="100%"
            width="100%"
        >
          HERE 2
        </TabPanel>
        <Stack
          direction="row"
          display="flex"
          alignItems="center"
          justifyContent="center"
          spacing={1}
          p={1}
        >
          <Button
              sx={{
                width:"30%",
                height:"100%"
              }}
              onClick={_handleModify}
            >
              MODIFY
          </Button>
          <Button
              sx={{
                width:"30%",
                height:"100%"
              }}
              onClick={_handleCancel}
            >
              CANCEL
          </Button>
        </Stack>
      </Dialog>
    </Box>
  );
}

export default DashboardWidgetSimpleChartConsumption;
