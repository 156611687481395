const postToDatabase = async (url, dataToWrite, accToken) => {
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accToken}`,
        },
        body: JSON.stringify(dataToWrite),
      });
  
      // Gestisci la risposta del server se necessario
      return response;
    } catch (error) {
      console.error('Errore durante l\'operazione di scrittura:', error);
    }
};

const putToDatabase = async (url, dataToWrite, accToken) => {
  try {
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accToken}`,
      },
      body: JSON.stringify(dataToWrite),
    });

    // Gestisci la risposta del server se necessario
    return response;
  } catch (error) {
    console.error('Errore durante l\'operazione di scrittura:', error);
  }
};

const readFromDatabase = async (url, accToken, enHeader = false, customHeaders = {}) => { 
  try {
      const headers = {
          Authorization: `Bearer ${accToken}`,
      };

      if (enHeader) {
          Object.keys(customHeaders).forEach((key) => {
              headers[key] = customHeaders[key];
          });
      }

      const options = {
          method: 'GET',
          headers: headers,
      };

      const response = await fetch(url, options);

      return response;

  } catch (error) {
      console.error('Errore durante l\'operazione di lettura:', error);
  }
};



export { postToDatabase, putToDatabase, readFromDatabase };