import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { CircularProgress, Stack } from '@mui/material';
import GenGauge from '../../../GeneralComponents/GenGauge';
import BoltIcon from '@mui/icons-material/Bolt';
import DeviceThermostatIcon from '@mui/icons-material/DeviceThermostat';
import ElectricMeterIcon from '@mui/icons-material/ElectricMeter';
import OpacityIcon from '@mui/icons-material/Opacity';
import { dateFormat, dateFormatToString, isParsableDate } from '../../../../utils/utils';

const DashboardSensorCard = ({ sensorData, avgEnabled, timeInterval, text, renderSize, channel, iconTag, timestamp, loading }) => {
    const [size, setSize] = useState(renderSize);
    const [updated, setUpdated] = useState(false);
  
    let displayedData = 'Waiting';
    let displayedSign = 'kWh';

    if (sensorData && sensorData.length > 0) {
        
      let accumulator = 0;
      if (sensorData.length > 0) {
          sensorData.forEach((element) => {
            if (
              element &&
              element.data &&
              (!avgEnabled || (element?.avg !== null && element?.avg !== undefined))
            ) {
                for(let count = 0; count < element.data.length; count++) {
                    accumulator += element.data[count].convertedVal[channel] / (element.data.length);
                }
            }
          });
      }

      let timeIntervalfactor;

      switch (timeInterval) {
        case 'live':
          timeIntervalfactor = 1/60;
          break;
        case 'hour':
          timeIntervalfactor = 1/60;
          break;
        case 'day':
          timeIntervalfactor = 1/12;
          break;
        case 'week':
          timeIntervalfactor = 1;
          break;
        case 'month':
          timeIntervalfactor = 24;
          break;
        case 'all':
          timeIntervalfactor = 24;
          break;
        default:
          timeIntervalfactor = 60;
      }

      displayedData = (accumulator * timeIntervalfactor);

      if(displayedData >= 1000){
        displayedData = displayedData / 1000;
        displayedSign = "MWh";
      }

      if(displayedData >= 1000){
        displayedData = displayedData / 1000;
        displayedSign = "GWh";
      }
    }
  
    useEffect(() => {
      setSize(renderSize);
      setUpdated(true);
  
      const timer = setTimeout(() => {
        setUpdated(false);
      }, 1500);
  
      return () => clearTimeout(timer);
    }, [renderSize, sensorData]);
  
    useEffect(() => {
      setSize(renderSize);
    }, [renderSize]);
  
    const convertedTimestamp = isParsableDate(timestamp)
      ? dateFormatToString(dateFormat(new Date(timestamp)), 'dd/MM/yyyy HH:mm:ss')
      : 'waiting';
  
    return (
      <Box width="100%" height="100%">
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <CircularProgress />
          </Box>
        ) : (
          <Box width="100%" height="100%">
            {size === 'small' && (
              <Stack
                display="flex"
                alignContent="center"
                justifyContent="space-evenly"
                direction="row"
                width="100%"
                height="100%"
              >
                <Box sx={{ width: "20%", display: 'flex', justifyContent: 'center', alignItems: 'center', color: updated ? 'green' : 'initial' }} >
                  {iconTag === 'ElectricMeterIcon' && <ElectricMeterIcon fontSize="large" />}
                  {iconTag === 'BoltIcon' && <BoltIcon fontSize="large" />}
                  {iconTag === 'DeviceThermostatIcon' && <DeviceThermostatIcon fontSize="large" />}
                  {iconTag === 'OpacityIcon' && <OpacityIcon fontSize="large" />}
                </Box>
                <Box sx={{ width: "45%", display: 'flex', justifyContent: "flex-start", alignItems: 'center' }}>
                  <Stack direction="column" justifyContent="center" alignContent="center">
                    <Typography
                      sx={{ fontSize: '0.90srem', fontWeight: 'bold', color: updated ? 'green' : 'initial', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                    >
                      {text}
                    </Typography>
                    <Typography
                      sx={{ fontSize: '0.65rem', color: updated ? 'green' : 'initial', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                    >
                      {convertedTimestamp}
                    </Typography>
                  </Stack>
                </Box>
                <Box sx={{ width: "35%", display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'row-reverse' }}>
                  <Typography
                    sx={{ pr: 2, fontSize: '1.00rem', fontWeight: 'bold', color: updated ? 'green' : 'initial', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'right' }}
                  >
                    {displayedData != null
                      ? typeof displayedData === 'number'
                          ? (
                              <>
                                {displayedData.toFixed(2)} 
                                <Typography component="span" sx={{ ml: 0.25, fontSize: '0.9rem', fontWeight: 'bold' }}>
                                    {displayedSign}
                                </Typography>
                              </>
                            )
                          : displayedData
                      : 'waiting'}
                  </Typography>
                </Box>
              </Stack>
            )}
            {size === 'medium' && (
              <Stack>
                <Typography sx={{ fontSize: '1.5em', color: updated ? 'green' : 'text.primary' }} gutterBottom>
                  {sensorData?.channel}
                </Typography>
                <Typography sx={{ fontSize: '2.0em', color: updated ? 'green' : 'text.primary' }} gutterBottom>
                  {sensorData?.type}
                </Typography>
                <Typography
                  sx={{ fontSize: '3.5em', color: updated ? 'green' : 'text.primary' }}
                  gutterBottom
                >
                  {displayedData != null
                    ? typeof displayedData === 'number'
                      ? `${displayedData.toFixed(2)} ${displayedSign}`
                      : displayedData
                    : 'waiting'}
                </Typography>
              </Stack>
            )}
            {size === 'large' && (
              <Stack
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                alignContent="center"
                direction="column"
              >
                <Box>
                  <Typography sx={{ fontSize: '1.5em', color: updated ? 'green' : 'text.primary' }} gutterBottom>
                    {sensorData?.channel}
                  </Typography>
                </Box>
                <Box>
                  <Typography sx={{ fontSize: '2.0em', color: updated ? 'green' : 'text.primary' }} gutterBottom>
                    {sensorData?.type}
                  </Typography>
                </Box>
                <Box>
                  <GenGauge
                    value={sensorData === undefined ? 0.0 : sensorData?.val}
                    data={[
                      { name: 'Start', value: sensorData === undefined ? 33.33 : sensorData?.range[1] / 3, color: '#00ff00' },
                      { name: 'Middle', value: sensorData === undefined ? 66.66 : sensorData?.range[1] / 3, color: '#0000ff' },
                      { name: 'End', value: sensorData === undefined ? 100.0 : sensorData?.range[1] / 3, color: '#ff0000' },
                    ]}
                    width={340}
                    height={200}
                    cx={170}
                    cy={140}
                    iR={80}
                    oR={100}
                    color="black"
                    range={sensorData === undefined ? [0, 100] : sensorData?.range}
                    sign=""
                  />
                </Box>
                <Box>
                  <Typography
                    sx={{ fontSize: '3.5em', color: updated ? 'green' : 'text.primary' }}
                    gutterBottom
                  >
                    {displayedData != null
                      ? typeof displayedData === 'number'
                        ? `${displayedData.toFixed(2)} ${displayedSign}`
                        : displayedData
                      : 'waiting'}
                  </Typography>
                </Box>
              </Stack>
            )}
          </Box>
        )}
      </Box>
    );
  };

const DashboardDeviceWidgetConsumptionsCard = ({ sensorData, avgEnabled, timeInterval, text, renderSize, channel, iconTag, timestamp, loading }) => {
  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
        width: "100%",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <DashboardSensorCard
        sensorData={sensorData}
        avgEnabled={avgEnabled}
        timeInterval={timeInterval}
        text={text}
        renderSize={renderSize}
        channel={channel}
        iconTag={iconTag}
        timestamp={timestamp}
        loading={loading}
      />
    </Box>
  );
};

export default DashboardDeviceWidgetConsumptionsCard;
