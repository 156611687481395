import React, { useState, useMemo } from 'react';
import { Grid, Icon, Button, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { Stack } from '@mui/system';
import { Typography } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import DashboardDeviceVisualization from '../DashboardPanelComponents/DashboardDeviceVisualization';
import SettingsInputAntennaIcon from '@mui/icons-material/SettingsInputAntenna';
import AddIcon from '@mui/icons-material/Add';
import GenSearchBar from '../../GeneralComponents/GenSearchBar';
import { devicesAddDevice, selectDevices } from '../../../services/devicesSlice';
import DashboardPageDeviceAddDeviceForm from '../DashboardPageDeviceComponents/DashboardPageDeviceAddDeviceForm';
import { selectDataHistory, selectDataLast } from '../../../services/dataSlice';
//import { dataUpdateVType, selectDataHistory, selectDataLast } from '../../../services/dataSlice';
{/* import DashboardOverallVisualization from '../DashboardPanelComponents/DashboardOverallVisualization';*/}

function DashboardPageDevicePanel() {

    const jsonDataHistory = useSelector(selectDataHistory)
    const jsonDataLast = useSelector(selectDataLast)
    const devices = useSelector(selectDevices)

    const dispatch = useDispatch()

    const [onDialogOpen, setOnDialogOpen] = useState([false]);
    const [searchTerm, setSearchTerm] = useState('');
    const [viewMode, setViewMode] = useState('devices'); // Stato per il toggle

    function handleDialogClose(event) {
        setOnDialogOpen([false])
    }
    
    function handleDialogAddDeviceSave(deviceJsonOut) {
        
        devices.map(function(element) {
            if(element.id === deviceJsonOut.id || 
                element.dev_eui === deviceJsonOut.dev_eui )
                {
                    // TODO : "Set Dialog Error"   
                }
                return;
        })

        dispatch(devicesAddDevice(deviceJsonOut))
        setOnDialogOpen([false])
    }
    
    function handleAddDeviceFormOpen(event) {
        setOnDialogOpen([true])
    }

    const handleSearchChange = (value) => {
        setSearchTerm(value);
    };

    const filteredDevices = useMemo(() => 
        devices.filter(device => 
            device.name?.toLowerCase().includes(searchTerm.toLowerCase())
        ),
    [devices, searchTerm]);

    const handleViewModeChange = (event, newViewMode) => {
        if(newViewMode !== null && newViewMode !== "overall" && newViewMode !== "devices")
        {
            //ERROR RETURN
            return
        }
        else{
            setViewMode(newViewMode);
            //dispatch(dataUpdateVType(newViewMode));
        }
    };

    return (
        <Grid
            container
            direction="column"
            wrap="nowrap"
        >
            <Grid 
                item
                xs={1}
                display="flex"
                alignItems="center"
            >
                <Grid
                    container
                    direction="row"
                >
                    <Grid
                        item
                        xs={6}
                    >
                        <Typography
                            fontSize={20}
                            pl={5}
                            pt={4}
                            sx={{ fontWeight: 'bold' }}
                        >
                            <Icon>
                                <SettingsInputAntennaIcon />
                            </Icon>
                            <span style={{ marginLeft: 8 }} />
                            DEVICES
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        alignItems="end"
                    >
                        <Stack
                            direction="row"
                            justifyContent="end"
                            spacing={1}
                            pt={4}
                            pr={5}
                        >
                            <GenSearchBar onSearchChange={handleSearchChange} />
                            <Button
                                variant="contained"
                                onClick={handleAddDeviceFormOpen}
                                startIcon={<AddIcon />}
                                disabled={true}                           
                            >
                                Add Device
                            </Button>
                            <DashboardPageDeviceAddDeviceForm
                                dialogTitle={"Add Device"} 
                                dialogContextText={""}
                                buttonLabel0={"Save"}
                                buttonLabel1={"Cancel"}
                                open={onDialogOpen[0]}
                                handleClose={handleDialogClose}
                                handleButton0={handleDialogAddDeviceSave}
                            />
                        </Stack>
                    </Grid>
                </Grid>
            </Grid>
            
            {/* 
            <Grid item xs={1} display="flex" justifyContent="flex-start" alignItems="center" pt={2} pl={5}>
                <ToggleButtonGroup
                    value={viewMode}
                    exclusive
                    onChange={handleViewModeChange}
                >
                    <ToggleButton value="devices">Devices</ToggleButton>
                    <ToggleButton value="overall">Overall</ToggleButton>
                </ToggleButtonGroup>
            </Grid>
            */}

            <Grid  // Visualization Panel
                item
                xs={11}
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                pt={2}
                pl={5}
            >
                <DashboardDeviceVisualization jsonData={jsonDataLast} devices={filteredDevices} />
{/*                 
{viewMode === 'devices' ? (
                    <DashboardDeviceVisualization jsonData={jsonDataLast} devices={filteredDevices} />
                ) : (
                    <DashboardOverallVisualization jsonData={jsonDataLast} devices={filteredDevices} />
                )}
*/}
            </Grid>
        </Grid>  
    )
}

export default DashboardPageDevicePanel;
