import * as React from 'react';
import { DataGrid, GridActionsCellItem, GridToolbar } from '@mui/x-data-grid';
import { devicesDeleteDevice, devicesModifyDevice } from '../../../services/devicesSlice';
import { useDispatch } from 'react-redux';
import GenOnlineCircle from '../../GeneralComponents/GenOnlineCircle';
import { Box } from '@mui/material';
import { dateFormat, dateFormatToString, isParsableDate } from '../../../utils/utils';
import { dataUpdateIntervalType } from '../../../services/dataSlice';
import { useCustomNavigate } from '../../../navigation/customNavigate';

function createRow(dev,json) {

    const convertedTimestamp = isParsableDate(dev?.lastSeen)
    ?  dateFormatToString(dateFormat(dev?.lastSeen), 'dd/MM/yyyy HH:mm:ss')
    : 'Waiting';

    const filled =
        dev?.status === 'created' ? false :
        dev?.status === 'offline' ? false :
        dev?.status === 'online' ? true :
        false;

    return {
        id: dev?.name, // ID
        status: filled, 
        device_eui:dev?.dev_eui, 
        reported_at:convertedTimestamp, // Last Seen
        //primary:json?.data[0]?.values[0]?.val + json?.data[0]?.values[0]?.sign, //primary ",
        //secondary:json?.data[0]?.values[1]?.val + json?.data[0]?.values[1]?.sign, //secondary",
        //payload:json?.data[0]?.body?.payload, // Payload
        //hotspotsNumb:json?.data[0]?.body?.hotspotsNum, // Number
        //hotspotsMeta:`RSSI: ${parseFloat(json?.data[0]?.body?.hotspotsMeta[0]?.rssi,10).toFixed(2)} SNR: ${parseFloat(json?.data[0]?.body?.hotspotsMeta[0]?.snr).toFixed(2)}`, 
        //battery:"", 
        //serialNumb:"",
        //hotspotLocation:"", 
        tags:"", 
        //product:"", 
        productType:dev?.sensor_type,
        //deviceLocation:`Latitude: ${parseFloat(dev?.location?.latitude,10)} Longitude: ${parseFloat(dev?.location?.longitude)} Altitude: ${parseFloat(dev?.location?.altitude)}`, 
    };
}

function DashboardDeviceList({jsonData, devices}) {

    const rows = []

    const dispatch = useDispatch()
    const customNavigate = useCustomNavigate()

    for (const el of devices) {

        const devIndex = devices.findIndex(element => element.id === el.id)

        const dataIndex = jsonData.findIndex(element => element.id === el.id)

        rows.push(createRow(devices[devIndex], jsonData[dataIndex]))
    }

    const onHandleModify = React.useCallback(
        (id) => () => {
            dispatch(devicesModifyDevice(id))
        },
        [dispatch]
    )
    
    const onHandleDelete = React.useCallback(
        (id) => () => {
            dispatch(devicesDeleteDevice(id))
        },
        [dispatch]
    )

    const onDeviceButtonClick = React.useCallback(

        (name) => () => {

            const devIndex = devices.findIndex(element => element.name === name)

            const obj = {
                "deviceId": devices[devIndex].id,
                "intervalType": "live"
            }
          
            //const jsonParsed = JSON.stringify(obj)

            customNavigate( ``, `/DashboardPage/DashboardDeviceScope/`, obj, {} );
        },[customNavigate]
    )

    const columns = [
        {
            field: 'status',
            headerName: 'Status',
            width: 100,
            type: 'actions',
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <GenOnlineCircle filled={params.value} />
              ),
        },
        {
            field: 'name',
            headerName: 'Name',
            width: 280,
            type: 'actions',
            headerAlign: 'center',
            align: 'center',
            getActions: (params) => [
                <GridActionsCellItem
                    sx={{
                        color:"primary.main",
                        '&:hover': {
                            backgroundColor: 'transparent',
                            color: "red"
                        }
                    }}
                    icon=
                    {
                        <Box>
                            {params.id}
                        </Box>
                    }
                    label=""
                    onClick={onDeviceButtonClick(params?.id)}
                />,
            ]
        },
        {
            field: 'device_eui',
            headerName: 'Device Eui',
            width: 280,
            type: 'string',
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'reported_at',
            headerName: 'Last Seen',
            width: 280,
            type: 'string',
            headerAlign: 'center',
            align: 'center'
        },
        // {
        //     field: 'primary',
        //     headerName: 'Primary',
        //     width: 280,
        //     type: 'string'
        // },
        // {
        //     field: 'secondary',
        //     headerName: 'Secondary',
        //     width: 280,
        //     type: 'string'
        // },
        // {
        //     field: 'payload',
        //     headerName: 'Payload',
        //     width: 280,
        //     type: 'string'
        // },
        // {
        //     field: 'hotspotsNumb',
        //     headerName: 'Hot Spots Number',
        //     width: 280,
        //     type: 'string'
        // },
        // {
        //     field: 'hotspotsMeta',
        //     headerName: 'Radio Signal',
        //     width: 280,
        //     type: 'string'
        // },
        // {
        //     field: 'battery',
        //     headerName: 'Battery',
        //     width: 280,
        //     type: 'string'
        // },
        {
            field: 'serialNumb',
            headerName: 'Serial Number',
            width: 280,
            type: 'string',
            headerAlign: 'center',
            align: 'center'
        },
        // {
        //     field: 'hotspotLocation',
        //     headerName: 'Hot Spot Location',
        //     width: 280,
        //     type: 'string'
        // },
        {
            field: 'tags',
            headerName: 'Tags',
            width: 280,
            type: 'string',
            headerAlign: 'center',
            align: 'center'
        },
        // {
        //     field: 'product',
        //     headerName: 'Product',
        //     width: 280,
        //     type: 'string'
        // },
        {
            field: 'productType',
            headerName: 'Product Type',
            width: 280,
            type: 'string',
            headerAlign: 'center',
            align: 'center'
        },
        // {
        //     field: 'deviceLocation',
        //     headerName: 'Device Location',
        //     width: 280,
        //     type: 'string'
        // },
        // {
        //     field: 'actions',
        //     type: 'actions',
        //     width: 280,
        //     getActions: (params) => [
        //         <GridActionsCellItem
        //             icon={<AllOutIcon />}
        //             label=""
        //             onClick={()=> {alert("")}}
        //         />,
        //         <GridActionsCellItem
        //             label="Modify Device"
        //             onClick={onHandleModify(params.id)}
        //             showInMenu
        //         />,
        //         <GridActionsCellItem
        //             label="Delete Device"
        //             onClick={onHandleDelete(params.id)}
        //             showInMenu
        //         />,
        //     ],
        //   }
    ];

    return (
        <DataGrid
            columns={columns}
            rows={rows}
            autoHeight
            disableDensitySelector
            disableColumnMenu
            backgroundColor="blue"
            disableColumnFilter
            components={{
                Toolbar: GridToolbar,
            }}
            background="blue"
        />
  );
}

export default DashboardDeviceList;