import { Box, Button, CircularProgress, FormControlLabel, Stack, Switch, ToggleButton, ToggleButtonGroup } from "@mui/material";
import RGL, { WidthProvider } from "react-grid-layout";
import { useEffect, useState } from "react";
import DashboardDeviceWidgetAlarm from "./DashboardDeviceWidgets/DashboardDeviceWidgetAlarm";
import DashboardDeviceWidgetTimestamp from "./DashboardDeviceWidgets/DashboardDeviceWidgetTimestamp";
import DashboardDeviceWidgetRadio from "./DashboardDeviceWidgets/DashboardDeviceWidgetRadio";
import DashboardDeviceWidgetCard from "./DashboardDeviceWidgets/DashboardDeviceWidgetCard";
import { useDispatch, useSelector } from "react-redux";
import { widgetsUpdateWidget } from "../../../services/widgetsSlice";
import AlertDialog from "../../GeneralComponents/GenDialog";
import DashboardWidgetSimpleChart from "./DashboardDeviceWidgets/DashboardDeviceWidgetsCharts/DashboardWidgetSimpleChart";
import DashboardDeviceWidgetPhaseActive from "./DashboardDeviceWidgets/DashboardDeviceWidgetActive";
import DashboardDeviceWidgetPh from "./DashboardDeviceWidgets/DashboardDeviceWidgetPh";
import DashboardDeviceWidgetPd from "./DashboardDeviceWidgets/DashboardDeviceWidgetPd";
import DashboardDeviceWidgetPTot from "./DashboardDeviceWidgets/DashboardDeviceWidgetPTot";
import DashboardDeviceWidgetIRms from "./DashboardDeviceWidgets/DashboardDeviceWidgetIRms";
import DashboardDeviceWidgetVRms from "./DashboardDeviceWidgets/DashboardDeviceWidgetVRms";
import DashboardDeviceWidgetF from "./DashboardDeviceWidgets/DashboardDeviceWidgetF";
import DashboardDeviceWidgetPrice from "./DashboardDeviceWidgets/DashboardDeviceWidgetPrice";
import DashboardDeviceWidgetCo2 from "./DashboardDeviceWidgets/DashboardDeviceWidgetCo2";
import DashboardDeviceWidgetPricePtot from "./DashboardDeviceWidgets/DashboardDeviceWidgetPricePtot";
import DashboardDeviceWidgetPtotCo2Ptot from "./DashboardDeviceWidgets/DashboardDeviceWidgetCo2Ptot";
import DashboardWidgetMulti3Chart from "./DashboardDeviceWidgets/DashboardDeviceWidgetsCharts/DashboardWidgetMulti3Chart";
import { selectUserInfo, selectUserToken } from "../../../services/userSlice";
import { selectWebSocketInstance } from "../../../services/wsSlice";
import { sendWsRequest } from "../../../ws/webSocketServiceRequests";
import DashboardDeviceWidgetConsumptionsCard from "./DashboardDeviceWidgets/DashboardDeviceWidgetConsumptionsCard";
import { dataResetDataLive, dataUpdateAllData, dataUpdateCustomIntervalType, dataUpdateIntervalType, selectCustomIntervalType, selectIntervalType } from "../../../services/dataSlice";
import { postToDatabase, putToDatabase } from "../../../db/dbAccessFunctions";
import DashboardWidgetSimpleChartConsumption from "./DashboardDeviceWidgets/DashboardDeviceWidgetsCharts/DashboardWidgetSimpleChartConsumption";
import GenDateTimeRangePicker from "../../GeneralComponents/GenDateTimePicker";
import { AlertProvider } from "../../GeneralComponents/GenAlert";
import ResetLiveButton from "../../GeneralComponents/ResetLiveButton";
import GenExportCsv from "../../GeneralComponents/GenExportCsv";
import { DateTime } from 'luxon';
import DashboardDeviceWidgetTep from "./DashboardDeviceWidgets/DashboardDeviceWidgetTep";
import DashboardDeviceWidgetTepN from "./DashboardDeviceWidgets/DashboardDeviceWidgetTepN";

const ENDPOINT_BASE = process.env.REACT_APP_BACKEND_URL || 'localhost:3003';
const ENDPOINT_BASE_TEST = process.env.REACT_APP_BACKEND_URL || 'localhost:3003';

const ReactResponsiveGridLayout = WidthProvider(RGL);

function DashboardDeviceScopeDashboard({sensorData, sensorDataLive, widgetData, deviceData, intervalType}) {

    const accToken = useSelector(selectUserToken)
    const userInfo = useSelector(selectUserInfo)
    const ws = useSelector(selectWebSocketInstance)
    const customIntervalType = useSelector(selectCustomIntervalType)

    const [layouts, setLayouts] = useState([]);
    const [modify, setModify] = useState(false);
    const [widgetArray, setWidgetArray] = useState(widgetData)
    const [isModified, setIsModified] = useState(false)
    const [onDialogOpen, setOnDialogOpen] = useState([false, false]);
    const [isExportButtonLoading, setIsExportButtonLoading] = useState(false);
    const [batchLoading, setBatchLoading] = useState(false);
    const [isSaveButtonLoading, setIsSaveButtonLoading] = useState(false);

    const dispatch = useDispatch()

    let sensorDataVals = []
    
    function handleDialogClose(event) {
        setOnDialogOpen([false])
    }

    const handleLiveButtonClick = async () => {
        setBatchLoading(true)
        // Flush data
        dispatch(dataUpdateIntervalType("live"))
        setBatchLoading(false)
    };

    const handleHourButtonClick = async () => {
        setBatchLoading(true);
        await sendWsRequest(ws, 'getData', {clientId: userInfo.meta.company_id, sensorId: deviceData?.id, interval: "hour"})
        //await sendWsRequest(ws, 'getData', {clientId: userInfo.meta.company_id, sensorId: deviceData?.id, interval: "hour", startDate: null, endDate: null, avgType: 1})
        setBatchLoading(false);
    };
    
    const handleDayButtonClick = async () => {
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);

        setBatchLoading(true);
        //await sendWsRequest(ws, 'getData', {clientId: userInfo.meta.company_id, sensorId: deviceData?.id, interval: "day"})
        await sendWsRequest(ws, 'getData', {clientId: userInfo.meta.company_id, sensorId: deviceData?.id, interval: "day", startDate: currentDate, endDate: null, avgType: 5})
        setBatchLoading(false);
    };
    
    const handleWeekButtonClick = async () => {
        const currentDate = new Date();
        const currentDayOfWeek = currentDate.getDay();
        
        const daysUntilMonday = currentDayOfWeek === 0 ? 6 : currentDayOfWeek - 1;
    
        currentDate.setHours(0, 0, 0, 0);
        
        currentDate.setDate(currentDate.getDate() - daysUntilMonday);
        setBatchLoading(true);
        //await sendWsRequest(ws, 'getData', {clientId: userInfo.meta.company_id, sensorId: deviceData?.id, interval: "week"})
        await sendWsRequest(ws, 'getData', {clientId: userInfo.meta.company_id, sensorId: deviceData?.id, interval: "week", startDate: currentDate, endDate: null, avgType: 60})
        setBatchLoading(false);
    };
    
    const handleMonthButtonClick = async () => {
        const currentDate = new Date();
    
        currentDate.setHours(0, 0, 0, 0);
        
        currentDate.setDate(1);
        setBatchLoading(true);
        //await sendWsRequest(ws, 'getData', {clientId: userInfo.meta.company_id, sensorId: deviceData?.id, interval: "month"})
        await sendWsRequest(ws, 'getData', {clientId: userInfo.meta.company_id, sensorId: deviceData?.id, interval: "month",startDate: currentDate, endDate: null, avgType: 1440})
        setBatchLoading(false);
    };
    
    
    const handleAllButtonClick = async () => {
        setBatchLoading(true);
        //await sendWsRequest(ws, 'getData', {clientId: userInfo.meta.company_id, sensorId: deviceData?.id, interval: "all"})
        await sendWsRequest(ws, 'getData', {clientId: userInfo.meta.company_id, sensorId: deviceData?.id, interval: "all", startDate: null, endDate: null, avgType: 1440})
        setBatchLoading(false);
    };

    const handleCustomButtonClick = async () => {
        setBatchLoading(true)
        dispatch(dataUpdateAllData({
            "sensorId":deviceData?.id,
            "data":[],
            "intervalType": "custom"
        }))
        //await sendWsRequest(ws, 'getData', {clientId: userInfo.meta.company_id, sensorId: deviceData?.id, interval: "custom", startDate:"", endDate:"", avgType:""})
        setBatchLoading(false)
    };

    const handleButtonChange = (event, newButton) => {
        if (newButton !== null) {

            // Chiama la funzione corrispondente al pulsante selezionato
            switch (newButton) {
                case 'live':
                handleLiveButtonClick();
                break;
                case 'hour':
                handleHourButtonClick();
                break;
                case 'day':
                handleDayButtonClick();
                break;
                case 'week':
                handleWeekButtonClick();
                break;
                case 'month':
                handleMonthButtonClick();
                break;
                case 'all':
                handleAllButtonClick();
                break;
                case 'custom':
                handleCustomButtonClick();
                break;
                default:
                break;
            }
        }
    }

    const handleExportClick = async (intervalEnFlag, startDateTime, endDateTime) => {

        setIsExportButtonLoading(true)

        const match = deviceData?.id.match(/^cl-[a-z]+-([0-9a-f]{16})$/);

        const zone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        if (!match) {
            alert("ERROR IN FETCHING CSV")
            setIsExportButtonLoading(false);
            return;
        }

        const hexPart = match[1];

        const queryParams = {
            clientId: userInfo.meta.company_id,
            deviceId: hexPart,
            startDate: startDateTime,
            endDate: endDateTime,
            zone: zone
        };

        let export_csv_url = null;
        if (process.env.REACT_APP_ENV  === 'production') {
            export_csv_url = `https://${ENDPOINT_BASE}/export-csv/`;
        } else if (process.env.REACT_APP_ENV  === 'test') {
            export_csv_url = `https://${ENDPOINT_BASE_TEST}/export-csv/`;
        }
        else{
            export_csv_url = `http://${ENDPOINT_BASE}/export-csv/`;
        }

        try {
            const response = await postToDatabase(export_csv_url, queryParams, accToken);
      
            if (!response.ok) {
                console.error('Errore nella richiesta:', response.status, response.statusText);
                return;
            }

            const contentDispositionHeader = response.headers.get('content-disposition');

            const filenameMatch = contentDispositionHeader && contentDispositionHeader.match(/filename="(.+)"/);

            let filename = filenameMatch

            if (filenameMatch && filenameMatch.length > 1) {
                filename = filenameMatch[1];
            }
      
            const csvBlob = await response.blob();
        
            const blobUrl = URL.createObjectURL(csvBlob);
        
            const downloadLink = document.createElement('a');
            downloadLink.href = blobUrl;

            let intNameString = ""
            if(intervalEnFlag){
                intNameString=`${startDateTime.toFormat("ddMMyyyyHHmm")}_${endDateTime.toFormat("ddMMyyyyHHmm")}`
            } else {
                intNameString=`${DateTime.fromJSDate((new Date())).toFormat("ddMMyyyyHHmm")}`
            }
            downloadLink.download = `export_${sensorData?.id}_${intNameString}.zip`;
        
            downloadLink.click();
        
            URL.revokeObjectURL(blobUrl);

            setIsExportButtonLoading(false)
        } catch (error) {
            console.error('Errore nella richiesta:', error);
        }
    };
      
    const handleChangeModifyEnable = (event) => {
        if (modify) {
          if (isModified) {
            setOnDialogOpen([true, ...onDialogOpen.slice(1)]);
            setModify(event.target.checked);
          } else {
            setModify(event.target.checked);
          }
        } else {
          setModify(event.target.checked);
        }
      };
      

    const handleModify = (layouts, layout) => {
        const oldArray = JSON.parse(JSON.stringify(widgetArray));
        const tempArray = JSON.parse(JSON.stringify(widgetArray.widgets));
        setLayouts(layout);
        for (const position of layouts) {
            tempArray[Number(position.i)].genMeta.grid.x = position.x;
            tempArray[Number(position.i)].genMeta.grid.y = position.y;
            tempArray[Number(position.i)].genMeta.grid.width = position.w;
            tempArray[Number(position.i)].genMeta.grid.height = position.h;
            
            if ( tempArray[Number(position.i)].genMeta.grid.height < 4 )
                { tempArray[Number(position.i)].genMeta.grid.renderSize="small"; }
            else if( tempArray[Number(position.i)].genMeta.grid.height >= 4 && tempArray[Number(position.i)].genMeta.grid.height < 7 )
                { tempArray[Number(position.i)].genMeta.grid.renderSize="medium"; }
            else{
                tempArray[Number(position.i)].genMeta.grid.renderSize="large";
            }
        };

        oldArray.widgets = tempArray;
        setWidgetArray(oldArray);
        setIsModified(true);
      };
    
    const handleModifyWidget = (widgetIndex, updatedWidget) => {
        
        let updatedWidgetsArray = JSON.parse(JSON.stringify(widgetArray));

        if (updatedWidgetsArray && updatedWidgetsArray.widgets) {
          let updatedWidgets = [...updatedWidgetsArray.widgets];
          updatedWidgets[widgetIndex] = updatedWidget;
          updatedWidgetsArray.widgets = updatedWidgets;
        }
        setWidgetArray(updatedWidgetsArray);
        setIsModified(true)
    }

    const handleAdd = () => {
        setWidgetArray(
            { widgets: [...widgetArray, { i: "widget" + (widgetArray.length + 1), x: 0, y: 0, w: 2, h: 2 }], count: widgetArray.count + 1}
        );
    };

    const handleSave = async () => {

        let url_widget_save = null;
        if (process.env.REACT_APP_ENV  === 'production') {
            url_widget_save = `https://${ENDPOINT_BASE}/widgets/`;
        } else if (process.env.REACT_APP_ENV  === 'test') {
            url_widget_save = `https://${ENDPOINT_BASE_TEST}/widgets/}`;
        }
        else{
            url_widget_save = `http://${ENDPOINT_BASE}/widgets/`;
        }

        setIsSaveButtonLoading(true)

        const match = deviceData?.id.match(/^cl-[a-z]+-([0-9a-f]{16})$/);

        if (!match) {
            alert("ERROR IN SAVING")
            setIsSaveButtonLoading(false);
            return;
        }

        const hexPart = match[1];

        const responseData = await putToDatabase(`${url_widget_save}${userInfo.meta.company_id}/${hexPart}`, widgetArray, accToken)

        if(responseData.ok) {
            dispatch(widgetsUpdateWidget({widgetArray}))
            alert("CORRECTLY SAVED")
        }
        else
        {
            alert("ERROR IN SAVING")
        }

        setIsSaveButtonLoading(false);
    };

    const handleSaveButton = () => {
        handleSave()
        handleDialogClose()
        setModify(false);
    }

    const handleApplyClick = async (startDateTime, endDateTime, avgInterval) => {
        setBatchLoading(true)

        let intervalTypeUp=""
        switch(avgInterval)
        {
            case 1:
            {
                intervalTypeUp='live'
                break;
            }
            case 5:
            {
                intervalTypeUp='day'
                break;
            }
            case 60:
            {
                intervalTypeUp='week'
                break;
            }
            case 1440:
            {
                intervalTypeUp='month'
                break;
            }
            default:
            {
                intervalTypeUp='live'
                break;                
            }

        }
        
        dispatch(dataUpdateCustomIntervalType(intervalTypeUp))

        await sendWsRequest(ws, 'getData', {clientId: userInfo.meta.company_id, sensorId: deviceData?.id, interval: "custom", startDate: startDateTime.toJSDate(), endDate: endDateTime.toJSDate(), avgType: avgInterval})
        setBatchLoading(false)
    }

    const handleResetClick = async () => {
        setBatchLoading(true)

        dispatch(dataUpdateAllData({
            "sensorId":deviceData?.id,
            "data":[],
            "intervalType": "custom"
        }))
        
        dispatch(dataUpdateCustomIntervalType('none'))

        setBatchLoading(false)
    }

    const handleResetLiveClick = async () => {
        dispatch(dataResetDataLive({
            "sensorId":deviceData?.id
        }))
    }

    const handleDelete = (key) => {
        const oldArray = JSON.parse(JSON.stringify(widgetArray))
        const tempArray = (JSON.parse(JSON.stringify(widgetArray?.widgets))).slice();
        const index = tempArray.indexOf(tempArray.find((widget) => widget?.genMeta?.grid?.i === key));
        tempArray.splice(index, 1);
        oldArray.widgets = tempArray;
        setWidgetArray(oldArray);
        setIsModified(true)
    };

    return (
        <Box>
            <Stack
                display="flex"
                sx = {{
                    position: 'relative',
                    transition: 'height 0.2s ease',
                    // altri stili CSS
                }}
            >
                {/*<Box
                    alignSelf="end"
                >
                    { modify ? (
                        <Button
                            alignSelf="end"
                            className="addButton"
                            onClick={() => handleAdd()}
                        >
                            Add Widget
                        </Button>
                        ) : ( null )
                    }
                </Box>*/}
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Box>
                        <ToggleButtonGroup
                            value={intervalType}
                            color="primary"
                            exclusive
                            onChange={handleButtonChange}
                            aria-label="Platform"
                            sx={{
                                display: 'flex', // Rendi flessibile il contenitore per allineare i pulsanti orizzontalmente
                            }}
                        >
                            <ToggleButton
                                value="live"
                                disabled={batchLoading}
                                sx={{
                                    width: '6em',
                                    height: '100%'
                                }}
                            >
                                Live
                            </ToggleButton>
                            <ToggleButton
                                value="hour"
                                disabled={batchLoading}
                                sx={{
                                    width: '6em',
                                    height: '100%'
                                }}
                            >
                                Hour
                            </ToggleButton>
                            <ToggleButton
                                value="day"
                                disabled={batchLoading}
                                sx={{
                                    width: '6em',
                                    height: '100%'
                                }}
                            >
                                Day
                            </ToggleButton>
                            <ToggleButton
                                value="week"
                                disabled={batchLoading}
                                sx={{
                                    width: '6em',
                                    height: '100%'
                                }}
                            >
                                Week
                            </ToggleButton>
                            <ToggleButton
                                value="month"
                                disabled={batchLoading}
                                sx={{
                                    width: '6em',
                                    height: '100%'
                                }}
                            >
                                Month
                            </ToggleButton>
                            <ToggleButton
                                value="all"
                                disabled={batchLoading}
                                sx={{
                                    width: '6em',
                                    height: '100%'
                                }}
                            >
                                All
                            </ToggleButton>
                            <ToggleButton
                                value="custom"
                                disabled={batchLoading}
                                sx={{
                                    width: '6em',
                                    height: '100%'
                                }}
                            >
                                Custom
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Box>
                    <Box>
                        <GenExportCsv handleCreateCsvLiveClick={handleExportClick} isLoading={isExportButtonLoading} />
                    </Box>
                </Box>
                <Box
                    alignSelf="end"
                >
                    { isSaveButtonLoading ?
                        (
                            <CircularProgress
                                size={24} 
                                color="inherit"
                            />
                        ) : (
                            <Box>
                                <FormControlLabel
                                    control={<Switch color={isModified ? "secondary" : "primary"}
                                    checked={modify}
                                    onChange={handleChangeModifyEnable} />}
                                    position="end"
                                    disabled={false}
                                    label=""
                                />
                                <AlertDialog
                                    dialogTitle={"Do you want to save changes?"} 
                                    dialogContextText={""}
                                    buttonLabel0={"Save"}
                                    buttonLabel1={"Cancel"}
                                    open={onDialogOpen[0]} 
                                    handleClose={handleDialogClose}
                                    handleButton0={handleSaveButton}
                                />
                            </Box>
                        )
                    }
                </Box>
                <Box>
                    {intervalType==='custom' && <AlertProvider><GenDateTimeRangePicker handleApplyClick={handleApplyClick} handleResetClick={handleResetClick} isLoading={batchLoading} /></AlertProvider>}
                </Box>
                <Box>
                    {intervalType==='live' && <AlertProvider><ResetLiveButton handleResetLiveClick={handleResetLiveClick} ></ResetLiveButton></AlertProvider>}
                </Box>
                <Box
                    bgcolor="#F3F4F6"
                    borderRadius={3}
                    sx={{ height: '100%' }}
                >
                    <ReactResponsiveGridLayout
                        className="layout"
                        onLayoutChange={handleModify}
                        layout={layouts}
                        margin={[10,12]}
                        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
                        autoSize={true}
                        isResizable= {false}
                        isDraggable= {false}
                        cols={12}
                        rowHeight={50}
                        width={2200}
                    >
                        {widgetArray?.widgets?.map((widget, index) => {

                            if((widget?.genMeta?.liveButtonEnabled===true) && intervalType!=='live')
                            {
                                return null;
                            }

                            return (
                                <Box 
                                    key={index}
                                    sx={{
                                        boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
                                        backgroundColor:"white",
                                        borderRadius: 3,
                                        display: "flex",
                                        flexDirection: "column", // Allineamento verticale
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}
                                    data-grid={{
                                        x: widget?.genMeta?.grid?.x,
                                        y: widget?.genMeta?.grid?.y,
                                        w: widget?.genMeta?.grid?.w,
                                        h: widget?.genMeta?.grid?.h,
                                        i: widget?.genMeta?.grid?.i,
                                        minW: 3,
                                        maxW: Infinity,
                                        minH: 0,
                                        maxH: Infinity,
                                        isDraggable: {modify},
                                        isResizable: {modify}
                                    }}
                                >
                                    {/*<Box
                                        alignSelf="end"
                                    >
                                        { modify ? (
                                            <Button
                                                alignSelf="end"
                                                className="deleteButton"
                                                onClick={() => handleDelete(widget?.genMeta?.grid?.i)}
                                            >
                                                x
                                            </Button>
                                            ) : ( null )
                                        }
                                    </Box>*/}
                                    { widget?.type==="DashboardDeviceWidgetCard" ?  (
                                        <DashboardDeviceWidgetCard 
                                            sensorData={ intervalType !== 'live' ? Array.isArray(sensorData?.data) && 
                                                sensorData?.data[sensorData?.data?.length - 1]?.data[sensorData?.data[sensorData?.data?.length - 1].data.length - 1] ? 
                                                sensorData?.data[sensorData?.data?.length - 1]?.data[sensorData?.data[sensorData?.data?.length - 1].data.length - 1]?.convertedVal : null : 
                                                Array.isArray(sensorDataLive?.data) && 
                                                sensorDataLive?.data[sensorDataLive?.data?.length - 1]?.data[sensorDataLive?.data[sensorDataLive?.data?.length - 1].data.length - 1] ? 
                                                sensorDataLive?.data[sensorDataLive?.data?.length - 1]?.data[sensorDataLive?.data[sensorDataLive?.data?.length - 1].data.length - 1]?.convertedVal : null } 
                                            avgEnabled={intervalType!=='live' ? 
                                                sensorData?.data[sensorData?.data?.length - 1]?.dataInfo?.avg : 
                                                sensorDataLive?.data[sensorDataLive?.data?.length - 1]?.dataInfo?.avg } 
                                            sign={widget?.specMeta?.sign} 
                                            text={widget?.specMeta?.text} 
                                            channel={widget?.specMeta?.channel} 
                                            timestamp={intervalType!=='live' ? 
                                                sensorData?.data[sensorData?.data?.length - 1]?.reported_at : 
                                                sensorDataLive?.data[sensorDataLive?.data?.length - 1]?.reported_at} 
                                            iconTag={widget?.specMeta?.iconTag} 
                                            renderSize={widget?.genMeta?.grid?.renderSize} 
                                            loading={batchLoading}
                                        />
                                    ) : null }

                                    { widget?.type==="DashboardDeviceWidgetConsumptionsCard" ?  (
                                        <DashboardDeviceWidgetConsumptionsCard 
                                            sensorData={intervalType!=='live' ? sensorData?.data : sensorDataLive?.data } 
                                            avgEnabled={(intervalType!=='live' && intervalType!=='hour' && intervalType!=='custom')} 
                                            timeInterval={intervalType!=='custom' ? intervalType : customIntervalType} 
                                            text={widget?.specMeta?.text} 
                                            channel={widget?.specMeta?.channel} 
                                            timestamp={intervalType!=='live' ? 
                                                sensorData?.data[sensorData?.data?.length - 1]?.reported_at : 
                                                sensorDataLive?.data[sensorDataLive?.data?.length - 1]?.reported_at} 
                                            iconTag={widget?.specMeta?.iconTag} 
                                            renderSize={widget?.genMeta?.grid?.renderSize} 
                                            loading={batchLoading}
                                        />
                                    ) : null }

                                    { widget?.type==="DashboardDeviceWidgetRadio" ?  (
                                        <DashboardDeviceWidgetRadio 
                                            sensorData={intervalType!=='live' ? 
                                                sensorData?.data[sensorData?.data?.length - 1]?.dataInfo : 
                                                sensorDataLive?.data[sensorDataLive?.data?.length - 1]?.dataInfo} 
                                            avgEnabled={sensorData?.data[sensorData?.data?.length - 1]?.dataInfo?.avg} 
                                            renderSize={widget?.genMeta?.grid?.renderSize} 
                                            loading={batchLoading}
                                        />
                                    ) : null }

                                    { widget?.type==="DashboardDeviceWidgetTimestamp" ?  (
                                        <DashboardDeviceWidgetTimestamp 
                                            timestamp={sensorDataVals[0]?.t?.$date} 
                                            avgEnabled={sensorData?.data[sensorData?.data?.length - 1]?.dataInfo?.avg} 
                                            renderSize={widget?.genMeta?.grid?.renderSize} 
                                            loading={batchLoading}
                                        />
                                    ) : null }

                                    { widget?.type==="DashboardDeviceWidgetAlarm" ?  (
                                        <DashboardDeviceWidgetAlarm 
                                            timestamp={sensorDataVals[0]?.t?.$date} 
                                            avgEnabled={sensorData?.data[sensorData?.data?.length - 1]?.dataInfo?.avg} 
                                            renderSize={widget?.genMeta?.grid?.renderSize} 
                                            modifyEnable={true}
                                        />
                                    ) : null }

                                    { widget?.type==="DashboardWidgetSimpleChartConsumption" ?  (
                                        <DashboardWidgetSimpleChartConsumption 
                                            widgetIndex={index} 
                                            widgetData={widget} 
                                            deviceId={deviceData?.id} 
                                            data={intervalType!=='live' ? sensorData : sensorDataLive} 
                                            avgEnabled={(intervalType!=='live' && intervalType!=='hour' && intervalType!=='custom')} 
                                            liveAvgEnabled={(intervalType==='day' || intervalType==='week')} 
                                            lastElBlEnabled={( intervalType!=='live' && intervalType!=='hour' && intervalType!=='custom')} 
                                            fullScreen={false} 
                                            loading={batchLoading} 
                                            intervalType={intervalType!=='custom' ? intervalType : customIntervalType} 
                                            enlargeEnable={!modify} 
                                            modifyEnable={modify} 
                                            handleModify={handleModifyWidget} 
                                        />
                                    ) : null }

                                    { widget?.type==="DashboardWidgetSimpleChart" ?  (
                                        <DashboardWidgetSimpleChart 
                                            widgetIndex={index} 
                                            widgetData={widget} 
                                            deviceId={deviceData?.id} 
                                            data={intervalType!=='live' ? sensorData : sensorDataLive} 
                                            avgEnabled={(intervalType!=='live' && intervalType!=='hour' && intervalType!=='custom')} 
                                            liveAvgEnabled={(intervalType==='day' || intervalType==='week')} 
                                            lastElBlEnabled={( intervalType!=='live' && intervalType!=='hour' && intervalType!=='custom')} 
                                            loading={batchLoading} 
                                            intervalType={intervalType!=='custom' ? intervalType : customIntervalType} 
                                            fullScreen={false} 
                                            enlargeEnable={!modify} 
                                            modifyEnable={modify} 
                                            handleModify={handleModifyWidget} 
                                        />
                                    ) : null }
                                    { widget?.type==="DashboardWidgetMulti3Chart" ?  (
                                        <DashboardWidgetMulti3Chart 
                                            widgetIndex={index} 
                                            widgetData={widget} 
                                            deviceId={deviceData?.id} 
                                            data={intervalType!=='live' ? sensorData : sensorDataLive} 
                                            avgEnabled={(intervalType!=='live' && intervalType!=='hour' && intervalType!=='custom')} 
                                            liveAvgEnabled={(intervalType==='day' || intervalType==='week')} 
                                            lastElBlEnabled={( intervalType!=='live' && intervalType!=='hour' && intervalType!=='custom')} 
                                            loading={batchLoading} 
                                            intervalType={intervalType!=='custom' ? intervalType : customIntervalType} 
                                            fullScreen={false} 
                                            enlargeEnable={!modify} 
                                            modifyEnable={modify} 
                                            handleModify={handleModifyWidget} 
                                        />
                                    ) : null }

                                    { (widget?.type==="DashboardDeviceWidgetPrice") ?  (
                                        <DashboardDeviceWidgetPrice 
                                            sensorData={intervalType!=='live' ? sensorData?.data : sensorDataLive?.data} 
                                            avgEnabled={(intervalType!=='live' && intervalType!=='hour' && intervalType!=='custom')} 
                                            timeInterval={intervalType!=='custom' ? intervalType : customIntervalType} 
                                            cost={deviceData?.options?.cost} 
                                            sign={widget?.specMeta?.sign} 
                                            channel={widget?.specMeta?.channel} 
                                            renderSize={widget?.genMeta?.grid?.renderSize} 
                                            timestamp={intervalType!=='live' ? 
                                                sensorData?.data[sensorData?.data?.length - 1]?.reported_at : 
                                                sensorDataLive?.data[sensorDataLive?.data?.length - 1]?.reported_at} 
                                            text={"Cost"} 
                                            loading={batchLoading}
                                        />
                                    ) : null }

                                    { (widget?.type==="DashboardDeviceWidgetCo2") ?  (
                                        <DashboardDeviceWidgetCo2 
                                            sensorData={intervalType!=='live' ? sensorData?.data : sensorDataLive?.data} 
                                            avgEnabled={(intervalType!=='live' && intervalType!=='hour' && intervalType!=='custom')} 
                                            timeInterval={intervalType!=='custom' ? intervalType : customIntervalType} 
                                            co2Factor={widget?.specMeta?.co2Factor} 
                                            sign={widget?.specMeta?.sign} 
                                            channel={widget?.specMeta?.channel} 
                                            renderSize={widget?.genMeta?.grid?.renderSize} 
                                            timestamp={intervalType!=='live' ? 
                                                sensorData?.data[sensorData?.data?.length - 1]?.reported_at : 
                                                sensorDataLive?.data[sensorDataLive?.data?.length - 1]?.reported_at} 
                                            text={"CO2-e"} 
                                            loading={batchLoading}
                                        />
                                    ) : null }

                                    { (widget?.type==="DashboardDeviceWidgetTep") ?  ( 
                                        <DashboardDeviceWidgetTep 
                                            sensorData={intervalType!=='live' ? sensorData?.data : sensorDataLive?.data}
                                            avgEnabled={(intervalType!=='live' && intervalType!=='hour' && intervalType!=='custom')}
                                            timeInterval={intervalType!=='custom' ? intervalType : customIntervalType}
                                            tepFactor={widget?.specMeta?.tepFactor}
                                            sign={widget?.specMeta?.sign} channel={widget?.specMeta?.channel}
                                            renderSize={widget?.genMeta?.grid?.renderSize}
                                            timestamp={intervalType!=='live' ? 
                                                sensorData?.data[sensorData?.data?.length - 1]?.body?.reported_at : 
                                                sensorDataLive?.data[sensorDataLive?.data?.length - 1]?.body?.reported_at} 
                                            text={"Tonnellate Equivalenti di Petrolio"}
                                            loading={batchLoading}/>) : null }

                                    { (widget?.type==="DashboardDeviceWidgetTepN") ?  ( 
                                        <DashboardDeviceWidgetTepN 
                                            sensorData={intervalType!=='live' ? sensorData?.data : sensorDataLive?.data}
                                            avgEnabled={(intervalType!=='live' && intervalType!=='hour' && intervalType!=='custom')}
                                            timeInterval={intervalType!=='custom' ? intervalType : customIntervalType}
                                            tepFactor={widget?.specMeta?.tepFactor} tepType={widget?.specMeta?.tepType}
                                            channel={widget?.specMeta?.channel}
                                            renderSize={widget?.genMeta?.grid?.renderSize}
                                            timestamp={intervalType!=='live' ? 
                                                sensorData?.data[sensorData?.data?.length - 1]?.body?.reported_at : 
                                                sensorDataLive?.data[sensorDataLive?.data?.length - 1]?.body?.reported_at}
                                            text={"TEP Normalizzato"}
                                            loading={batchLoading}/>) : null }

                                    { (widget?.type==="DashboardDeviceWidgetPhaseActive" && sensorData.id==="Threephases_Test" && parseInt(widget?.genMeta?.grid?.i)===10) ?  (
                                        <DashboardDeviceWidgetPhaseActive 
                                            sensorData={sensorDataVals[0]} 
                                            renderSize={widget?.genMeta?.grid?.renderSize} 
                                            timestamp={sensorData?.data[0]?.reported_at} 
                                            text={"Fase A"}
                                        />
                                    ) : null }

                                    { (widget?.type==="DashboardDeviceWidgetPhaseActive" && sensorData.id==="Threephases_Test" && parseInt(widget?.genMeta?.grid?.i)===11) ?  (
                                        <DashboardDeviceWidgetPhaseActive 
                                            sensorData={sensorDataVals[0]} 
                                            renderSize={widget?.genMeta?.grid?.renderSize} 
                                            timestamp={sensorData?.data[0]?.reported_at} 
                                            text={"Fase B"}
                                        />
                                    ) : null }

                                    { (widget?.type==="DashboardDeviceWidgetPhaseActive" && sensorData.id==="Threephases_Test" && parseInt(widget?.genMeta?.grid?.i)===12) ?  (
                                        <DashboardDeviceWidgetPhaseActive 
                                            sensorData={sensorDataVals[0]} 
                                            renderSize={widget?.genMeta?.grid?.renderSize} 
                                            timestamp={sensorData?.data[0]?.reported_at} 
                                            text={"Fase C"}
                                        />
                                    ) : null }

                                    { (widget?.type==="DashboardDeviceWidgetIRms" && sensorData.id==="Threephases_Test" && parseInt(widget?.genMeta?.grid?.i)===6) ?  (
                                        <DashboardDeviceWidgetIRms 
                                            sensorData={sensorDataVals[parseInt(widget?.genMeta?.grid?.i)]} 
                                            renderSize={widget?.genMeta?.grid?.renderSize} 
                                            timestamp={sensorData?.data[0]?.reported_at} 
                                            text={"Fase A"}
                                        />
                                    ) : null }
                                    { (widget?.type==="DashboardDeviceWidgetIRms" && sensorData.id==="Threephases_Test" && parseInt(widget?.genMeta?.grid?.i)===7) ?  (
                                        <DashboardDeviceWidgetIRms 
                                            sensorData={sensorDataVals[parseInt(widget?.genMeta?.grid?.i)]} 
                                            renderSize={widget?.genMeta?.grid?.renderSize} 
                                            timestamp={sensorData?.data[0]?.reported_at} 
                                            text={"Fase B"}
                                        />
                                    ) : null }

                                    { (widget?.type==="DashboardDeviceWidgetIRms" && sensorData.id==="Threephases_Test" && parseInt(widget?.genMeta?.grid?.i)===8) ?  (
                                        <DashboardDeviceWidgetIRms 
                                            sensorData={sensorDataVals[parseInt(widget?.genMeta?.grid?.i)]} 
                                            renderSize={widget?.genMeta?.grid?.renderSize} 
                                            timestamp={sensorData?.data[0]?.reported_at} 
                                            text={"Fase C"}
                                        />
                                    ) : null }

                                    { (widget?.type==="DashboardDeviceWidgetVRms" && sensorData.id==="Threephases_Test" && parseInt(widget?.genMeta?.grid?.i)===3) ?  (
                                        <DashboardDeviceWidgetVRms 
                                            sensorData={sensorDataVals[parseInt(widget?.genMeta?.grid?.i)]} 
                                            renderSize={widget?.genMeta?.grid?.renderSize} 
                                            timestamp={sensorData?.data[0]?.reported_at} 
                                            text={"Fase A"}
                                        />
                                    ) : null }

                                    { (widget?.type==="DashboardDeviceWidgetVRms" && sensorData.id==="Threephases_Test" && parseInt(widget?.genMeta?.grid?.i)===4) ?  (
                                        <DashboardDeviceWidgetVRms 
                                            sensorData={sensorDataVals[parseInt(widget?.genMeta?.grid?.i)]} 
                                            renderSize={widget?.genMeta?.grid?.renderSize} 
                                            timestamp={sensorData?.data[0]?.reported_at} 
                                            text={"Fase B"}
                                        />
                                    ) : null }

                                    { (widget?.type==="DashboardDeviceWidgetVRms" && sensorData.id==="Threephases_Test" && parseInt(widget?.genMeta?.grid?.i)===5) ?  (
                                        <DashboardDeviceWidgetVRms 
                                            sensorData={sensorDataVals[parseInt(widget?.genMeta?.grid?.i)]} 
                                            renderSize={widget?.genMeta?.grid?.renderSize} 
                                            timestamp={sensorData?.data[0]?.reported_at} 
                                            text={"Fase C"}
                                        />
                                    ) : null }

                                    { (widget?.type==="DashboardDeviceWidgetF" && sensorData.id==="Threephases_Test" && parseInt(widget?.genMeta?.grid?.i)===9) ?  (
                                        <DashboardDeviceWidgetF 
                                            sensorData={sensorDataVals[parseInt(widget?.genMeta?.grid?.i)]} 
                                            renderSize={widget?.genMeta?.grid?.renderSize} 
                                            timestamp={sensorData?.data[0]?.reported_at}
                                        />
                                    ) : null }

                                    { (widget?.type==="DashboardDeviceWidgetPTot" && sensorData.id==="Threephases_Test" && parseInt(widget?.genMeta?.grid?.i)===0) ?  (
                                        <DashboardDeviceWidgetPTot 
                                            sensorData={sensorDataVals[parseInt(widget?.genMeta?.grid?.i)]} 
                                            renderSize={widget?.genMeta?.grid?.renderSize} 
                                            timestamp={sensorData?.data[0]?.reported_at}
                                        />
                                    ) : null }

                                    { (widget?.type==="DashboardDeviceWidgetPh" && sensorData.id==="Threephases_Test" && parseInt(widget?.genMeta?.grid?.i)===1) ?  (
                                        <DashboardDeviceWidgetPh 
                                            sensorData={sensorDataVals[parseInt(widget?.genMeta?.grid?.i)]} 
                                            renderSize={widget?.genMeta?.grid?.renderSize} 
                                            timestamp={sensorData?.data[0]?.reported_at}
                                        />
                                    ) : null }

                                    { (widget?.type==="DashboardDeviceWidgetPd" && sensorData.id==="Threephases_Test" && parseInt(widget?.genMeta?.grid?.i)===2) ?  (
                                        <DashboardDeviceWidgetPd 
                                            sensorData={sensorDataVals[parseInt(widget?.genMeta?.grid?.i)]} 
                                            renderSize={widget?.genMeta?.grid?.renderSize} 
                                            timestamp={sensorData?.data[0]?.reported_at}
                                        />
                                    ) : null }

                                    { (widget?.type==="DashboardDeviceWidgetPricePtot" && sensorData.id==="Threephases_Test" && parseInt(widget?.genMeta?.grid?.i)===16) ?  (
                                        <DashboardDeviceWidgetPricePtot 
                                            sensorData={sensorDataVals[parseInt(2)]} 
                                            renderSize={widget?.genMeta?.grid?.renderSize} 
                                            timestamp={sensorData?.data[0]?.reported_at} 
                                            text={"Costo Totale"}
                                        />
                                    ) : null }

                                    { (widget?.type==="DashboardDeviceWidgetCo2Ptot" && sensorData.id==="Threephases_Test" && parseInt(widget?.genMeta?.grid?.i)===19) ?  (
                                        <DashboardDeviceWidgetPtotCo2Ptot 
                                            sensorData={sensorDataVals[parseInt(2)]} 
                                            renderSize={widget?.genMeta?.grid?.renderSize} 
                                            timestamp={sensorData?.data[0]?.reported_at} 
                                            text={"CO2-e Totale"}
                                        />
                                    ) : null }

                                </Box>
                            );
                        })}
                    </ReactResponsiveGridLayout>
                </Box>
            </Stack>
        </Box>
    );
}

export default DashboardDeviceScopeDashboard;